import { css } from "@emotion/react"
import { motion } from "framer-motion"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import useMediaQuery from "./../useMediaQuery"

const links = {
  initial: {
    y: "100%",
    transition: {
      duration: 0.2,
      ease: "circIn",
    },
  },
  rest: {
    y: 0,
    transition: {
      ease: "circOut",
      duration: 0.3,
    },
  },
}
const container = {
  initial: {
    y: "-100%",
    transition: {
      duration: 0.3,
      ease: "circIn",
      delay: 0.2,
    },
  },
  rest: {
    y: 0,
    transition: {
      ease: "circOut",
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

export default function Navigation({ isOpen, toggle }) {
  const [isHovered, setIsHovered] = useState(null)
  const isDesktop = useMediaQuery()
  const {
    prismicContactUs,
    prismicBio,
    prismicAfrica,
    prismicFanpage,
    prismicCalendar,
    prismicPartners,
    prismicHighlights,
    prismicPress,
  } = useStaticQuery(graphql`
    {
      prismicBio {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicAfrica {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicPress {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicFanpage {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicCalendar {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicBornsvilkar {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicPartners {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicHighlights {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicContactUs {
        data {
          title {
            text
          }
        }
        uid
      }
    }
  `)

  return (
    <motion.div
      initial={"initial"}
      animate={isOpen ? "rest" : "initial"}
      variants={container}
      css={css`
        background-color: #051224;
      `}
      className="mobile-menu fixed-fullscreen z-40 flex items-center justify-center px-4 lg:px-8"
    >
      <div
        className="justify-center items-center flex flex-wrap"
        css={css`
          max-width: 957px;
          @media (max-width: 850px) and (orientation: landscape) {
            justify-content: space-around;
            height: 70vh;
            margin-top: 10vh;
            > div {
              margin-top: 10px !important;
              margin-bottom: 10px !important;
            }
            @media screen and (max-width: 1000px) and (orientation: landscape) {
              margin-top: 15vh;
              height: 40vh;
              justify-content: center;
              > div {
                margin-top: 0px !important;
                margin-bottom: 0px !important;
              }
            }
          }
          @media (max-width: 640px) {
            flex-wrap: nowrap;
            flex-direction: column;
          }
        `}
      >
        {[
          prismicHighlights,
          prismicBio,
          prismicAfrica,
          prismicFanpage,
          prismicPress,
          prismicCalendar,
          prismicPartners,
          prismicContactUs,
        ].map((page, i) => (
          <div
            key={i}
            className="overflow-hidden landscape:px-3 portrait:px-6 lg:landscape:px-8 lg:portrait:px-8 text-center"
          >
            <motion.div
              variants={links}
              onHoverStart={() => {
                setIsHovered(i)
              }}
              onHoverEnd={() => {
                setIsHovered(null)
              }}
            >
              <Link
                to={`/${page.uid}/`}
                onClick={(e) => {
                  e.preventDefault()
                  toggle()
                  setTimeout(() => {
                    navigate(`/${page.uid}/`)
                  }, 200)
                }}
                className="text-white py-2 block"
                css={css`
                  font-family: "Helvetica Neue";
                  font-size: 30px;
                  padding: ${!isDesktop ? "4px 0px" : "8px 0px"};
                  letter-spacing: 0.28px;
                  text-transform: uppercase;
                  opacity: ${isHovered === i || isHovered === null
                    ? "1"
                    : "0.5"};
                  transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
                  @media screen and (max-width: 700px) and (orientation: landscape) {
                    font-size: 26px;
                  }
                  &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: #fff;

                    transform: ${isHovered === i ? "scaleX(1)" : "scaleX(0)"};
                    transform-origin: ${isHovered === i
                      ? "center left"
                      : "center right"};
                    transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)
                      0s;
                  }
                `}
              >
                {page.data.title.text}
              </Link>
            </motion.div>
          </div>
        ))}
      </div>
    </motion.div>
  )
}
