exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-africa-jsx": () => import("./../../../src/templates/africa.jsx" /* webpackChunkName: "component---src-templates-africa-jsx" */),
  "component---src-templates-bio-jsx": () => import("./../../../src/templates/bio.jsx" /* webpackChunkName: "component---src-templates-bio-jsx" */),
  "component---src-templates-calendar-jsx": () => import("./../../../src/templates/calendar.jsx" /* webpackChunkName: "component---src-templates-calendar-jsx" */),
  "component---src-templates-color-jsx": () => import("./../../../src/templates/color.jsx" /* webpackChunkName: "component---src-templates-color-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-fanpage-jsx": () => import("./../../../src/templates/fanpage.jsx" /* webpackChunkName: "component---src-templates-fanpage-jsx" */),
  "component---src-templates-highlights-jsx": () => import("./../../../src/templates/highlights.jsx" /* webpackChunkName: "component---src-templates-highlights-jsx" */),
  "component---src-templates-partners-jsx": () => import("./../../../src/templates/partners.jsx" /* webpackChunkName: "component---src-templates-partners-jsx" */),
  "component---src-templates-press-jsx": () => import("./../../../src/templates/press.jsx" /* webpackChunkName: "component---src-templates-press-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

